h2,
h3 {
  font-family: "Noto Serif", serif;
  font-size: 22px;
}

.shop #rs .note-to-cusomer{
 background: rgba(252, 203, 133, 0.4);
 margin: 15px 15px 15px 0px;
}

.shop #rs .note-to-cusomer p{
  color: #6e2f16;
  padding: 10px;
  font-size: 16px;
}
.tab-view {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 50px;
  margin-top: 50px;
}

.tab-view section {
  width: 1200px;
  max-width: calc(100% - 20px);
}

.tab-view .tabs {
  max-width: 100vw;
  width: 1200px;
}

.tab-view .tabs span {
  color: #9c4727;
  font-size: 19px;
  margin: 0 20px;
  cursor: pointer;
  padding-bottom: 10px;
  font-family: "Noto Serif", serif;
  text-transform: capitalize;
}

.tab-view .first {
  border-bottom: 1px solid rgba(156, 71, 39, 0.2);
  margin-bottom: 30px;
  padding-bottom: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tab-view section .tabs span:first-child {
  margin-left: 0;
}

.tab-view section .tabs span:last-child {
  margin-right: 0;
}

.tab-view .tabs span:hover {
  color: #6e2f16;
}

/* .tab-view .content {
    padding-left: 100px;
} */

.tab-view .content p,
.tab-view .content h2,
.tab-view .content h3,
.tab-view .content ul,
.tab-view .content ol {
  margin-bottom: 15px;
  letter-spacing: 0.5px;
}

.tab-view .content a {
  text-decoration: none;
  color: #000;
}

.tab-view .content a:hover {
  font-weight: bold;
}

.tab-view .content h3 {
  font-size: 22px;
  font-weight: normal;
}

.tab-view .content p {
  font-size: 18px;
  line-height: 26px;
}

.tab-view .tabs span.active {
  color: #382924;
  border-bottom: 1px solid #382924;
}

/* .tab-view .content .images {
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    width: 100%;
    padding: 0;
    align-items: flex-start;
} */

/* .tab-view .content .images section {
    display: flex;
    flex-wrap: wrap
}

.tab-view .content .images section img {
    max-width: calc(100vw - 20px);
} */

/* .tab-view .content .images section:first-child img {
    width: 1000px;
    margin-bottom: 30px;
}

.tab-view .content .images section:nth-child(2) img {
    width: 700px;
} */
/* 
.tab-view .content .images section:nth-child(3) {
    margin-left: -5px;
    margin-right: -5px;
}

.tab-view .content .images section:nth-child(3) img {
    width: 150px;
    height: 120px;
    margin: 5px;
} */

/* .tab-view .content .images section:nth-child(3) img:first-child {
    margin-left: 0;
}

.tab-view .content .images section:nth-child(3) img:last-child {
    margin-right: 0;
} */
.ReviewShop{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ReviewShop textarea{
  width: 500px;
  max-width: 100%;
  color: #382924;
  border: 1px solid rgba(0, 0, 0, 0.2);
  font-size: 16px;
  padding: 10px 0;
  padding-left: 10px;
  outline: none;
}

.ReviewShop .buy-now{
  outline: none !important;
  border: none;
}
.tab-view .content .images {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  margin-top: 50px;
}

.tab-view .content ul,
.tab-view .content ol {
  font-size: 18px;
  margin-left: 20px;
  line-height: 26px;
}

.tab-view .content .images img {
  width: 1200px;
  margin: 10px 0;
  max-width: 100%;
}

.product {
  width: 1200px;
  max-width: 100vw;
  display: flex;
  margin: 50px auto;
}

/* .product #ls {
    background: red;
} */

.product section:first-child {
  display: flex;
  width: 70px;
}

.product section:first-child .images {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 75px;
}

.product section:first-child .images img {
  width: 75px;
  margin: 5px 0;
  cursor: pointer;
}

.product section:first-child #up {
  width: 40px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

.product section:first-child #down {
  width: 40px;
  position: absolute;
  left: 50%;
  bottom: -25px;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

.main-image {
  height: 500.5px;
  margin-left: 15px;
  width: 500px;
  max-width: calc(100vw - 110px);
}

.product #ls {
  width: auto;
}

.product #rs {
  width: 500px;
  margin-left: 20px;
  max-width: calc(100% - 20px);
  color: #382924;
}

#rs h2 {
  font-family: "Noto Serif", serif;
  margin-bottom: 20px;
  line-height: 35px;
  letter-spacing: 0.5px;
  max-width: 100vw;
  color: #382924;
}

#rs .price {
  display: flex;
  align-items: center;
  margin-bottom: 0px;
  color: #382924;
}

#rs .price * {
  font-family: "Noto Serif", serif;
  color: #382924;
}

#rs .price h3 {
  font-size: 26px;
  margin: auto 0;
  margin-right: 10px;
}

#rs .price #real-price {
  font-size: 24px;
  margin-right: 10px;
  position: relative;
}

#rs .price #real-price:after {
  content: "";
  width: 100%;
  height: 2px;
  background: #382924;
  position: absolute;
  top: 50%;
  left: 0;
}

.price #discount {
  font-size: 26px;
  font-weight: bold;
  letter-spacing: 1px;
}

#rs .tax {
  color: #9c4727;
  font-size: 16px;
  margin-bottom: 20px;
  margin-top: 5px;
}

#rs h3 {
  font-family: "Noto Serif", serif;
  /* font-weight: normal; */
  font-size: 16px;
  letter-spacing: 0.5px;
  margin-bottom: 10px;
}

#rs p {
  font-size: 16px;
  max-width: 100vw;
  line-height: 22px;
}

.size {
  margin-top: 20px;
  margin-bottom: 30px;
}

.size h3 {
  font-size: 18px;
}

.size #ml {
  font-size: 15px;
  display: block;
  margin-bottom: 20px;
}

.sizes {
  height: auto;
}

.size .ml {
  font-size: 15px;
  padding: 10px;
  color: #382924;
  background: rgba(252, 203, 133, 0.4);
  margin: 0 5px;
  cursor: pointer;
}

.size .ml:hover {
  background: rgba(252, 203, 133, 0.5);
}

.sizes .ml:first-child {
  margin-left: 0;
}

.sizes .ml:last-child {
  margin-right: 0;
}

.sizes .ml.active {
  color: #9c4727;
  border: 1px solid #9c4727;
}

.purchase {
  display: flex;
}

.purchase * {
  margin: 0 5px;
}

#rs .quantity {
  color: #9c4727;
  font-size: 18px;
  border: 1px solid #9c4727;
  padding: 10px 0;
  display: flex;
  justify-content: center;
}

.quantity span {
  display: flex;
  margin: 0 15px;
}

.quantity span {
  cursor: pointer;
}

.quantity span span {
  background: #9c4727;
  height: 1.5px;
  width: 15px;
  margin: auto;
}

.quantity span:hover {
  color: #444;
}

.quantity span span:hover {
  background: #444;
}

.add-to-cart {
  color: #9c4727;
  border: 1px solid #9c4727;
  padding: 10px 0;
  width: 200px;
  text-align: center;
  letter-spacing: 0.5px;
}

.add-to-cart:hover {
  background: #9c4727;
  color: #fff;
  cursor: pointer;
}

.buy-now {
  background: #9c4727;
  color: #fccb85;
  padding: 10px 0;
  width: 200px;
  text-align: center;
  cursor: pointer;
}

.buy-now:hover {
  background: #bd5630;
}

.purchase div:first-child {
  margin-left: 0;
}

.purchase div:last-child {
  margin-right: 0;
}

.shop .reviews .review {
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin: 20px 0;
  /* padding: 0 20px; */
  padding-bottom: 15px;
}

.shop .reviews .review:first-child {
  margin-top: 0;
}

.shop .reviews .review h3 {
  font-size: 16px;
  font-weight: bold;
  margin: 5px 0;
}

.shop .reviews .review p {
  font-size: 16px;
  color: #666;
}

@media (max-width: 1180px) {
  .shop .product {
    flex-direction: column;
    align-items: center;
  }

  .product #rs {
    margin-left: 0;
    margin-top: 20px;
  }
}

@media (max-width: 540px) {
  .sizes {
    display: flex;
    /* flex-direction: column;*/
    align-items: center;
    flex-wrap: wrap;
    margin: 0 -5px;
  }

  .sizes .ml {
    display: block;
    margin: 5px !important;
    font-size: 18px;
    width: calc(50% - 20px);
  }
}

@media (max-width: 445px) {
  .tab-view .tabs span {
    font-size: 15px;
    margin: 0 10px;
  }


}
