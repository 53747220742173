.product-cart {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
}

.product-cart .main-container {
  width: 1200px;
  max-width: 100%;
}

.product-cart h2 {
  margin-bottom: 13px;
  font-size: 23px;
  letter-spacing: 0.5px;
}

.product-cart .header {
  width: 100%;
  background: rgba(254, 234, 206, 0.5);
  padding: 10px;
  font-size: 14px;
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  box-shadow: none;
}

.product-cart .header span {
  font-family: "Noto Serif";
  font-weight: bold;
  letter-spacing: 0.5px;
  display: block;
  width: 25%;
  text-align: center;
}

.product-cart .product {
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin-top: 0;
  /* height: 100px; */
  align-items: center;
}

.product-cart .product .first {
  display: flex;
  align-items: center;
  justify-content: start;
}

.product-cart .product .first img {
  width: 80px;
  margin-right: 20px;
}

.product-cart .product section {
  width: 20%;
  display: flex;
  justify-content: center;
}

.product-cart .product .first span {
  font-size: 14px;
  /* white-space: nowrap; */
  font-family: "Noto Serif";
  font-weight: bold;
  letter-spacing: 0.5px;
}

.product-cart .product .second span,
.product-cart .product .fourth span {
  font-size: 16px;
}

.product-cart .third .quantity {
  display: flex;
  padding: 5px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  height: 30px;
  font-size: 16px;
  color: #000;
}

.product-cart .third .quantity div {
  margin: 0 3px;
  width: 20px;
  display: grid;
  place-items: center;
  cursor: pointer;
}

.product-cart .third .quantity div:first-child {
  margin-left: 0;
}

.product-cart .product {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding-bottom: 20px;
  margin-bottom: 15px;
}

.product-cart .third .quantity .desc {
  display: grid;
  place-items: center;
}

.product-cart .third .quantity .desc span {
  width: 10px;
  height: 1.5px;
  display: flex;
  background: #000;
  margin: 0;
}

.product-cart .product .desc {
  margin-left: 0;
}

.product-cart .actions {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  margin-bottom: 50px;
  padding: 0 10px;
}

.product-cart .actions span {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #9c4727;
  font-weight: bold;
  letter-spacing: 0.5px;
  cursor: pointer;
}

.product-cart .actions span img {
  width: 15px;
  margin-right: 10px;
}

.product-cart main {
  width: 100%;
  display: flex;
  margin-bottom: 100px;
}

.product-cart main section {
  width: 100%;
  height: auto;
  background: rgba(254, 234, 206, 0.5);
  margin: 0 5px;
  max-width: 100%;
}

.product-cart main section p {
  font-family: "Noto serif";
  font-weight: bold;
  margin-bottom: 30px;
  width: 100%;
  font-size: 14px;
  letter-spacing: 0.5px;
}

.product-cart main section textarea {
  width: 100%;
  height: 200px;
  background: transparent;
  resize: none;
  outline: none;
  border: 1px solid #9c4727;
  padding: 10px;
  font-size: 16px;
}

.product-cart main section textarea:focus {
  border: 2px solid #9c4727;
}

.product-cart main section .roww {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.product-cart main section .roww span {
  font-size: 16px;
  margin-bottom: 10px;
}

.product-cart main section .roww select {
  font-size: 14px;
  padding: 10px;
  background: transparent;
  border: 1px solid #9c4727;
  outline: none;
}

.product-cart main section .roww input {
  font-size: 14px;
  padding: 10px;
  background: transparent;
  outline: none;
  border: 1px solid #9c4727;
}

.product-cart main section .roww input:focus {
  border: 2px solid #9c4727;
}

.product-cart main section .roww .err {
  margin-top: 5px;
  font-size: 14px;
  color: #d63031;
}

.product-cart main section .roww button {
  color: #9c4727;
  background: transparent;
  border: 1px solid #9c4727;
  font-size: 16px;
  padding: 10px;
  cursor: pointer;
  margin-top: 10px; 
  width: 20%;
}

.product-cart main section .roww button:hover {
  background: #9c4727;
  color: #fff;
}

.product-cart main .third .roww {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 5px 0;
  align-items: center;
}

.product-cart main .third .roww:first-child span {
  font-size: 16px;
  font-weight: bold;
  /* letter-spacing: .5px; */
  font-family: "Noto Serif";
}

.product-cart main .third .roww:nth-child(2) span {
  font-size: 17px;
  color: #9c4727;
}

.product-cart main .third .roww:nth-child(3) span {
  font-size: 16px;
}

.product-cart main .third button {
  margin-top: 15px;
  width: 100%;
  text-align: center;
  padding: 10px 0;
  font-size: 16px;
  color: #fccb85;
  background: #9c4727;
  border: none;
  cursor: pointer;
}

.product-cart main .third button:hover {
  background: #ac5636;
}

.product.mobile {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: start;
  width: 100%;
  /* height: 120px; */
}

.product.mobile img {
  height: 100px;
}

.product.mobile section {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: calc(100% - 120px);
  justify-content: space-between;
  margin-left: 10px;
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.2); */
  padding: 0 10px;
}

.product.mobile section span {
  font-size: 16px;
  margin: 10px 0;
}

.product.mobile section .quantity {
  display: flex;
  padding: 5px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  height: 30px;
  font-size: 16px;
  color: #000;
  margin-top: 5px;
}

.product.mobile section .quantity div {
  margin: 0 3px;
  width: 20px;
  display: grid;
  place-items: center;
  cursor: pointer;
}

.product.mobile section .quantity div:first-child {
  margin-left: 0;
}

.product.mobile section .quantity .desc {
  display: grid;
  place-items: center;
}

.product.mobile section .quantity .desc span {
  width: 10px;
  height: 1.5px;
  display: flex;
  background: #000;
  margin: 0;
}

.product.mobile section .quantity .desc {
  margin-left: 0;
}

.container .total {
  font-size: 16px;
  font-weight: bold;
  font-family: "Noto Serif";
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
  margin-bottom: 50px;
  letter-spacing: 1px;
}

.container .total span:first-child {
  color: rgba(0, 0, 0, 0.6);
  font-size: 18px;
}

@media (max-width: 1205px) {
  .product-cart main {
    flex-wrap: wrap;
    justify-content: center;
  }

  .product-cart main section {
    margin: 10px;
  }
}



@media (max-width: 600px) {
  .product-cart main section .roww button{
    width: 35%;
  }
}
