.my-account-profile .profile-sub {
    display: flex;
    flex-direction: row;
    /* justify-content: space-between; */
    justify-content: center;
    gap: 5%;
}

.my-account-profile .cont {
    width: 100%;
    padding: 0 4%;
}

.my-account-profile .cont h1 {
    text-align: center;
    margin: 0 auto;
    margin-top: 30px;
    color: rgba(0, 0, 0, 0.7);
    letter-spacing: 1px;
    margin-bottom: 50px;
    border-bottom: 2px solid rgba(0, 0, 0, 0.1);
    padding-bottom: 15px;
    font-size: 26px;
    width: 900px;
    max-width: 100%;
}

.my-account-profile main {
    display: flex;
    justify-content: space-around;
}

.my-account-profile main .rs h2 {
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
    color: rgba(0, 0, 0, 0.7);
    letter-spacing: 1px;
    font-size: 20px;
}

.my-account-profile main .rs span {
    color: rgba(0, 0, 0, 0.7);
    letter-spacing: 1px;
}

.my-account-profile main .rs section {
    display: flex;
    flex-direction: column;
}

.my-account-profile main .rs section * {
    margin: 7px 0;
    font-size: 16px;
}

.addresses-sec {
    background: #fff;
    max-height: max-content;
    padding-bottom: 5%;
}

.addresses-sec h2 {
    width: 100%;
    text-align: center;
    margin-bottom: 2%;
    color: rgba(0, 0, 0, 0.7);
    letter-spacing: 1px;
    font-size: 18px;
}

.addresses-sec .card-sec {
    display: grid;
    grid-template-columns: auto auto;
    justify-content: center;
    gap: 2%;
}

.card-sec .sub-div {
    display: flex;
    width: 315px;
    height: 140px;
    flex-direction: column;
    border: 2px solid rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    margin: 2px;
    padding: 2% 7%;
    overflow-y: auto;
}

.sub-div .adres-sec {
    display: flex;
    flex-direction: row;
}

.adres-sec h4,
p {
    color: rgba(0, 0, 0, 0.7);
    letter-spacing: 1px;
}

@media only screen and (max-width: 1000px) and (min-width: 678px) {
    .my-account main {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .addresses-sec .card-sec {
        display: grid;
        grid-template-columns: auto auto;
        overflow-x: hidden;
    }

    .my-account-profile .profile-sub {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
}

@media (max-width: 678px) {
    .my-account main {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .addresses-sec .card-sec {
        display: grid;
        grid-template-columns: auto;
        overflow-x: hidden;
    }

    .my-account-profile .profile-sub {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
}