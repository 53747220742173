@import url("https://fonts.googleapis.com/css2?family=Noto+Serif&display=swap");

h2 {
  font-family: "Noto Serif", serif;
  font-size: 22px;
}

.header {
  width: 100%;
  background: #fff;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
}

.main-header-section {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.main-header-section .line {
  display: block;
  width: 100%;
  height: 1px;
  background: rgba(0, 0, 0, 0.1);
  position: absolute;
  top: calc(50% - 6px);
  left: 0;
}

.header-section {
  width: 1100px;
  max-width: calc(100% - 20px);
}

.top {
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding-bottom: 10px; */
}

/* .top .ls,
.top .rs {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
} */

.ls {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.availability {
  display: flex;
  align-items: center;
  width: 200px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding-bottom: 5px;
}

.availability span {
  color: #382924;
  font-size: 14px;
  font-weight: 400;
}

.location-icon {
  height: 16px;
  margin-right: 5px;
}

.icons {
  display: flex;
}

.icons a {
  height: 16px;
}

.icons img {
  height: 20px;
  margin: 0 10px;
  cursor: pointer;
}

.nav-bar {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  transition: all 300ms linear;
}

.fixed-nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: #fff;
  padding: 0 calc(50% - 550px);
  z-index: 99;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
}

.nav-bar section .logo {
  width: 60px;
  position: absolute;
  top: 6px;
}

section.navigations {
  display: flex;
  align-items: center;
  justify-content: center;
}

section.navigations a {
  text-decoration: none;
  font-size: 17px;
  margin: 0 15px;
  color: #9c4727;
  padding: 5px 7px;
  position: relative;
}

section.navigations a:after {
  content: "";
  height: 100%;
  width: 0;
  border-bottom: 3px solid #9c4727;
  position: absolute;
  top: 0;
  left: 0;
  transition: width 300ms linear;
}

section.navigations a:hover {
  font-weight: bold;
}

section.navigations a:hover:after {
  width: 100%;
}

/* section.navigations a:hover {
  font-weight: bold;
} */

.active-page {
  background: #9c472733;
}

.third-section .icons img {
  margin: 0 12px;
}

.top-offer {
  width: 100%;
  text-align: center;
  padding: 10px 10px;
  font-size: 15px;
  color: #fff;
  background: #9c4727;
}

.brand-logo {
  height: 100%;
  width: 60px;
}

.third-section {
  display: flex;
  align-items: center;
  justify-content: center;
  /* position: absolute;
  top: 0;
  right: 19.5%; */
  height: 100%;
}

.slider {
  width: 100%;
  /* height: 80vh; */
}

.slider-item {
  width: 100%;
  height: 100%;
  background-position: center !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
}

.swiper-button-prev {
  color: #fff !important;
}

.swiper-button-next {
  color: #fff !important;
}

.swiper-pagination-bullet {
  background: #fff !important;
}

.slider video {
  width: 100%;
  height: 100%;
}

.getToKnow {
  padding: 80px 0;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  /* padding-top: 130px; */
}

.getToKnow section {
  max-width: 100%;
}

.getToKnow section:first-child {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 350px;
  height: 250px;
}

.getToKnow section img {
  /* width: 500px; */
  object-fit: fill;
  width: 100%;
  height: 100%;
  /* max-height: 500px; */
}

.getToKnow section:nth-child(2) {
  width: 600px;
  max-width: 100%;
  margin-left: 16px;
}

.getToKnow section:nth-child(2) h2 {
  margin-bottom: 5px;
  color: #382924;
  font-family: "Noto Serif", serif;
}

.getToKnow section:nth-child(2) p {
  margin: 10px 0;
  line-height: 22px;
  color: #382924;
  font-size: 16px;
}

.getToKnow section:first-child {
  position: relative;
}

.getToKnow section:first-child:after {
  content: "";
  width: 1030%;
  height: 105%;
  background: #9c4727;
  position: absolute;
  top: -40px;
  left: -950%;
  z-index: -2;
  opacity: 0.1;
}

.getToKnow section:first-child:before {
  content: "";
  width: 100%;
  height: 90%;
  position: absolute;
  bottom: -30px;
  left: -40px;
  z-index: -1;
  border-radius: 8px;
  border: 1px solid #9c4727;
  opacity: 0.4;
}

.getToKnow section:nth-child(2) a {
  color: #9c4727;
  font-size: 14px;
  text-decoration: none;
  font-family: "Noto Serif", serif;
  display: flex;
  margin-top: 20px;
  font-weight: 500;
  align-items: center;
}

.getToKnow section:nth-child(2) a:hover {
  font-weight: bold;
}

.getToKnow section:nth-child(2) a img {
  height: 13px;
  width: 11px;
  margin-left: 3px;
}

.brand-usps {
  width: 100%;
  padding: 35px 0;
  background: #9c4727;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 60px;
}

.brand-usps * {
  color: #fccb85;
}

.brand-usps-cards {
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}

.brand-usps h2 {
  font-family: "Noto Serif", serif;
  margin-bottom: 30px;
}

.brand-usps-cards section {
  width: 290px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px 50px;
}

.brand-usps-cards section .brand-usps-image {
  height: 130px;
  max-width: 100%;
  margin-bottom: 15px;
}
.brand-usps-cards section .brand-usps-image img {
  width: 100%;
  max-height: 100%;
}

.brand-usps-cards section h3 {
  font-family: "Noto Serif", serif;
  font-weight: normal;
  margin-bottom: 15px;
  font-size: 18px;
  text-align: center;
}

.brand-usps-cards section p {
  font-size: 14px;
  width: 100%;
  text-align: center;
  line-height: 22px;
}

.sedut {
  width: 100%;
  display: flex;
  /* flex-wrap: wrap; */
  align-items: center;
  margin-bottom: 50px;
  justify-content: center;
}

.sedut .rs video {
  width: 450px;
  max-width: 100%;
}

.sedut .ls {
  flex-direction: column;
  align-items: flex-start;
  width: 650px;
  max-width: 100%;
  margin: 0 15px;
}

.sedut .ls h2 {
  margin-bottom: 10px;
  color: #382924;
  font-size: 26px;
  font-family: "Noto Serif", serif;
}

.sedut .ls p {
  margin-bottom: 30px;
  color: #382924;
  font-size: 16px;
  line-height: 23px;
}

/* .images {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-right: auto;
} */

/* .images img {
  width: 85px;
  margin: 0 35px;
}

.images img:first-child {
  margin-left: 0;
} */

.ingredients {
  width: 100%;
  background: rgba(252, 203, 133, 0.15);
  padding: 50px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-bottom: 50px;
}

.ingredients h2 {
  margin-bottom: 10px;
  font-family: "Noto Serif", serif;
  color: #382924;
}

.ingredients p {
  color: #382924;
  width: 800px;
  text-align: center;
  font-size: 16px;
  margin-bottom: 20px;
  max-width: calc(100% - 20px);
}

.ingredients .items {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.ingredients .items .item {
  width: 200px;
  margin: 10px;
  background: #fde4c3;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.ingredients .items .item .card {
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ingredients .items .item:hover .card img {
  display: none;
}

.ingredients .items .item:hover .card .text-content {
  display: flex;
  padding: 10px;
  text-align: center;
}

.ingredients .items .item img {
  width: 70%;
}

.ingredients .items .item span {
  color: #9c4727;
  border-top: 1px solid #edc288;
  padding: 10px;
  width: 100%;
  text-align: center;
  font-size: 20px;
}

.text-content {
  display: none;
}

.ingredients .items .item .card span {
  font-size: 13px;
  border: none;
  color: #000;
  width: calc(100% - 10px);
  height: calc(100% - 10px);
  text-align: left;
}

.bottom-art {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -1;
  opacity: 0.45;
  height: 30%;
}

.digital-ads {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;
}

.digital-ads-container h2 {
  font-family: "Noto Serif", serif;
  margin-bottom: 10px;
  color: #382924;
}

.digital-ads p {
  font-style: 14px;
  margin-bottom: 20px;
  color: #382924;
  padding: 0 10px;
}

/* .digital-ads section {
  width: 90%;
  max-width: 100%;
}

.digital-ads .first-section {
  margin-bottom: 10px;
}

.digital-ads .first-section iframe {
  width: 100%;
}

.digital-ads .second-section {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}

.digital-ads .second-section iframe {
  width: 50%;
}

.digital-ads .second-section iframe:nth-child(2) {
  margin-left: 20px;
  background: #000;
} */
.digital-ads-container {
  overflow-x: auto;
  white-space: nowrap;
  width: 100%;
  padding: 10px 0;
  margin: 0 auto;
  text-align: center;
}

.digital-ads-images-container {
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  margin: 0 auto;
  text-align: center;
  justify-content: center;
  gap: 1rem;
}

.digital-ads-item {
  display: inline-block;
  position: relative;
  margin: 0;
  cursor: pointer;
  width: 320px;
}

.digital-ads-item:nth-child(1) {
  width: 70%;
  height: 80vh;
  display: flex;
  /* background-color: #9c4727; */
}

.digital-ads-item:nth-child(2),
.digital-ads-item:nth-child(3) {
  width: 34.5%;
  display: flex;
  /* background-color: #9c4727; */
}

.digital-ads-thumbnail {
  width: 100%;
  height: auto;
  z-index: 1;
  max-height: 100%; /* Set a max-height for the images */
  object-fit: cover; /* Adjust object-fit according to your design needs */
}

.digital-ads-video {
  width: 100%;
  z-index: 1;
}

.digital-ads-video:nth-child(1) {
  height: inherit; /* Set a fixed height for the video player */
}

.collabs {
  background: rgba(252, 203, 133, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px 0;
  margin-bottom: 50px;
}

.collabs h2 {
  font-family: "Noto Serif", serif;
  font-size: 22px;
  margin-bottom: 10px;
  color: #382924;
}

.collabs p {
  font-size: 16px;
  margin-bottom: 10px;
  color: #382924;
  width: 800px;
  text-align: center;
  line-height: 22px;
  margin-bottom: 40px;
  max-width: calc(100% - 20px);
}

.collabs .images {
  width: 100%;
  display: flex;
  /* align-items: center; */
  justify-content: center;
  flex-wrap: nowrap;
  column-gap: 5%;
}

.collab-images-container {
  overflow-x: auto;
  white-space: nowrap;
  width: "100%";
  padding: 20px 0;
  margin: 0 auto;
  text-align: center;
}

.collab-thumbnail {
  width: 100%;
  height: auto;
  
  object-fit: fill;
}

.collabs .images section {
  display: flex;
  align-items: center;
  justify-content: center;
}

.collabs .images img {
  margin: 0;
  width: 300px;
  transition: filter 300ms linear;
  cursor: pointer;
}

.collabs .images img:hover {
  filter: grayscale(50%) brightness(0.85);
}

.youtube-reviews {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;
}

.youtube-reviews h2 {
  font-family: "Noto Serif", serif;
  font-size: 22px;
  margin-bottom: 10px;
  color: #382924;
}

.youtube-reviews p {
  font-size: 16px;
  color: #382924;
  width: 800px;
  text-align: center;
  line-height: 22px;
  margin-bottom: 30px;
  max-width: calc(100% - 20px);
}

.youtube-reviews .videos {
  display: flex;
  width: 90%;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.youtube-reviews .videos .frame-section:first-child {
  /* margin-right: 20px; */
  height: 80vh;
  display: flex;
}

.youtube-reviews .videos .frame-section iframe {
  width: 100%;
  max-width: 100%;
  height: inherit !important;
}
.youtube-reviews .videos .frame-section:nth-child(2) iframe {
  /* width: 100%; */
  height: 31vh !important;
}

.frame-div {
  display: flex;
  overflow: hidden;
  width: 100%;
}

.youtube-reviews .videos .frame-section:nth-child(2) {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.frame-section:nth-child(1) {
  overflow: hidden;
  width: 70%;
}
.frame-section:nth-child(2) {
  overflow: hidden;
  width: 30%;
}

.what-people {
  padding: 30px 0;
  background: rgba(252, 203, 133, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;
}

.what-people h2 {
  margin-bottom: 70px;
}

.what-people p {
  font-size: 16px;
  width: 800px;
  max-width: calc(100%-20px);
  text-align: center;
  margin-bottom: 50px;
  line-height: 23px;
  max-width: calc(100% - 20px);
}

.what-people main {
  display: flex;
  position: relative;
}

.what-people main section {
  display: flex;
  align-items: center;
  justify-content: center;
  /* height: 400px; */
  width: 600px;
  max-width: 100vw;
  /* background: red; */
  position: relative;
}

.what-people main section .sub-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  max-width: 100%;
}

.what-people main section .first {
  border-right: 2px solid rgba(252, 203, 133, 0.2);
}

.what-people main section .sub-section h3 {
  margin-bottom: 10px;
  font-size: 24px;
  font-family: "Noto Serif", serif;
  color: #382924;
  position: relative;
}

.what-people main section .sub-section p {
  width: 400px;
  text-align: center;
  font-size: 18px;
  line-height: 24px;
  max-width: calc(100% - 20px);
  color: #382924;
  margin-bottom: 50px;
}

.what-people main section .sub-section span {
  width: 400px;
  text-align: center;
  font-size: 22px;
  line-height: 24px;
  max-width: calc(100% - 20px);
  margin-bottom: 20px;
  color: #382924;
  font-family: "Noto Serif", serif;
  line-height: 28px;
  opacity: 0.9;
}

.what-people main section .sub-section .time {
  font-size: 13px;
  color: #382924;
}

.what-people main section .sub-section img {
  width: 50px;
  opacity: 0.1;
  position: absolute;
  top: calc(50% - 10px);
  left: 50%;
  transform: translate(-50%, -50%);
}

.what-people main section .sub-section h3 span {
  font-family: "Noto Serif", serif;
  color: rgba(0, 0, 0, 0.1);
  font-size: 30px;
  position: absolute;
  top: -10px;
  left: -45%;
}

.what-people main .label {
  font-size: 14px;
  font-family: "Noto Serif", serif;
  color: #382924;
  font-weight: bold;
  position: absolute;
}

.what-people main .label {
  right: 10px;
  top: -30px;
  z-index: 22;
  /* background: red; */
}

.what-people main .previos {
  left: 10px;
  top: -30px;
  z-index: 22;
}

.what-people main .label {
  display: flex;
  cursor: pointer;
}

.what-people main .label span {
  width: 45px;
  height: 2px;
  background: #382924;
  display: block;
  margin: 0 5px;
  margin-top: 8px;
}

.what-people main .label:hover {
  color: #777;
}

.what-people main .label:hover span {
  background: #777;
}

.avaible {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.avaible h2 {
  /* margin-bottom: 5px; */
  font-size: 22px;
}

.avaible section {
  white-space: nowrap;
  overflow-x: auto;
  text-align: center;
  /* padding: 0 5px; */
  width: 100%;
}

.avaible section::-webkit-scrollbar {
  display: none;
}

.avaible section a {
  margin: 0 15px;
}

.avaible section a img {
  width: 100px;
  display: inline;
}

.instagram {
  margin-top: 50px;
  margin-bottom: 100px;
  position: relative;
  transition: filter 300ms linear;
}

.instagram:hover {
  filter: brightness(0.8);
}

.instagram section {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.instagram a .react-multi-carousel-list ul li {
  height: 200px !important;
  width: 200px !important;
}

.instagram a .react-multi-carousel-list ul li img {
  height: 200px !important;
  width: 200px !important;
}

#instagram-id {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 100px;
  transform: translate(-50%, -50%);
  z-index: 66;
}

.main-footer {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  padding: 70px 0;
  background: #9c4727;
}

.main-footer * {
  color: #fccb85;
  text-decoration: none;
}

.main-footer section {
  display: flex;
  flex-direction: column;

  max-width: 100%;
}

.main-footer section .footer-logo-link {
  margin-bottom: 30px;
}

.main-footer section .footer-logo {
  height: 100px;
}

.main-footer section a {
  font-size: 20px;
  margin: 10px 0;
  display: flex;
  align-items: center;
}

.main-footer section a span:hover {
  color: #e7ba7c;
}

.main-footer section a img {
  height: 20px;
}

.main-footer a span {
  margin-left: 20px;
}

.email {
  margin-left: 5px !important;
}

.main-footer .social {
  display: flex;
  margin-top: 7px;
}

.main-footer .social img {
  margin: 0 25px;
  height: 20px;
}

.main-footer .social img:first-child {
  margin-left: 0;
}

.main-footer .links h2 {
  margin-bottom: 25px;
  letter-spacing: 1.5px;
  font-size: 24px;
}

.main-footer .links ul {
  list-style: none;
}

.main-footer .links ul li {
  margin: 15px 0;
}

.main-footer .links ul li a {
  font-size: 18px;
}

.main-footer .links ul li a:hover {
  color: #e7ba7c;
}

.active-link {
  color: #000000;
}

.main-footer .news-letter h2 {
  margin-bottom: 45px;
  letter-spacing: 1.5px;
  font-size: 24px;
}

.main-footer .news-letter input {
  color: #fccb85;
  background: transparent;
  border: 1px solid #fccb85;
  font-size: 16px;
  height: 35px;
  width: 280px;
  padding: 0 10px;
  /* padding-left: 50px; */
  letter-spacing: 0.5px;
  margin-bottom: 15px;
  outline: none;
  max-width: 100%;
}

.main-footer .news-letter input:focus {
  border: 1px solid #daaf74;
}

.main-footer .news-letter input::placeholder {
  color: #fccb85;
  text-align: center;
}

.main-footer .news-letter button {
  background: #fccb85;
  width: 100%;
  min-width: 280px;
  padding: 10px 0;
  text-align: center;
  font-size: 16px;
  color: #9c4727;
  border: none;
  outline: none;
  cursor: pointer;
  max-width: 100%;
}

.main-footer .news-letter button:hover {
  background: #e7ba7b;
}

.footer .copyright {
  width: 100%;
  padding: 15px 10px;
  background: #9c4727;
  color: #fccb85;
  font-size: 16px;
  text-align: center;
  letter-spacing: 0.5px;
  border-top: 1px solid rgba(252, 203, 133, 0.4);
}

.footer .popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.footer .popup-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
}

.footer .popup-content h2 {
  margin-bottom: 10px;
}

.footer .popup-content button {
  width: 40%;
  padding: 10px 20px;
  background-color: #9c4727;
  color: #fccb85;
  border: none;
  font-size: 16px;
  cursor: pointer;
}

.footer .popup-content button:hover {
  background-color: #612a16;
}

.erroremail {
  display: flex;
  justify-content: center;
  color: #000000;
}

.comming-soon {
  width: 100%;
  height: 50vh;
  display: grid;
  place-items: center;
}

.comming-soon h1 {
  font-size: 40px;
  color: #9c4727;
}

.cover {
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 89;
}

.side-menu {
  width: 300px;
  height: 100vh;
  position: fixed;
  top: 0;
  left: -500px;
  z-index: 90;
  display: flex;
  justify-content: center;
  transition: left 200ms linear;
}

.side-menu main {
  width: calc(100% - 40px);
  background: #fff;
  display: flex;
  flex-direction: column;
  padding: 20px 10px;
}

.side-menu main a {
  text-decoration: none;
  color: #333;
  border-bottom: 1px solid #333;
  padding: 15px 0;
  font-size: 16px;
}

.side-menu .close {
  width: 40px;
  height: 40px;
  background: #d63031;
  font-size: 28px;
  display: grid;
  place-items: center;
  cursor: pointer;
  color: #f3f3f3;
}

.mobile-nav {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  /* margin-bottom: 50px; */
  padding: 5px 0;
  display: none;
  transition: all 400ms linear;
}

.fixed-mobile-nav {
  position: fixed;
  top: 0;
  left: 0;
  background: #fff;
  width: 100%;
  margin: 0;
  padding-top: 12px;
  z-index: 88;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
}

.mobile-nav .third-section .icons img {
  margin: 0 10px;
}

.mobile-nav .mo-logo {
  width: 40px;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  margin-top: -5px;
}

#menu-section .menu-icon {
  margin-left: 10px;
  margin-top: -5px;
}

#menu-section .menu-icon img {
  width: 50px;
  margin: 2px 0;
  margin: -10px;
  opacity: 0.85;
}

body::-webkit-scrollbar {
  background: #fff;
  width: 12px;
}

body::-webkit-scrollbar-track {
  width: 10px;
}

body::-webkit-scrollbar-thumb {
  background: #e4b7a7;
  border-radius: 5px;
}

.auth-cover {
  z-index: 885;
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  top: 0;
  left: 0;
}

.auth {
  background: #fff;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  /* height: 400px; */
  z-index: 999;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 20px;
  max-width: calc(100% - 20px);
}

.auth h2 {
  font-size: 24px;
  margin-bottom: 5px;
  letter-spacing: 1px;
}

.auth p {
  font-size: 16px;
  margin-bottom: 10px;
}

.auth input {
  font-size: 18px;
  padding: 10px 0;
  padding-left: 10px;
  color: #382924;
  width: 350px;
  margin: 10px 0;
  outline: none;
  border: 1px solid rgba(0, 0, 0, 0.3);
  max-width: 100%;
}

.auth input:focus {
  border: 1px solid #9c4727;
}

.auth .forgot {
  width: 100%;
  text-align: right;
  font-size: 15px;
  margin-top: 10px;
  cursor: pointer;
}

.auth .forgot:hover {
  font-weight: bold;
}

.auth input::placeholder {
  color: #382924;
  opacity: 1;
}

.auth button {
  background: #9c4727;
  width: 250px;
  padding: 10px 0;
  text-align: center;
  border: none;
  color: #fccb85;
  font-size: 16px;
  font-family: "Noto Serif";
  cursor: pointer;
  margin-top: 20px;
}

.auth button:hover {
  background: #b86649;
}

.auth2 button {
  background: #9c4727;
  width: 250px;
  padding: 10px 0;
  text-align: center;
  border: none;
  color: #fccb85;
  font-size: 16px;
  font-family: "Noto Serif";
  cursor: pointer;
  margin-top: 20px;
}

.auth2 button:hover {
  background: #b86649;
}

.auth .bottom-text {
  font-size: 18px;
  letter-spacing: 0.5px;
  margin-top: 30px;
}

.auth .bottom-text span {
  color: #9c4727;
  font-weight: bold;
  cursor: pointer;
  letter-spacing: 1px;
}

.auth .err {
  color: #d63031;
  font-size: 14px;
  padding: 3px 0;
  text-align: left;
  max-width: 100%;
  width: 350px;
  text-transform: capitalize;
  margin-top: -10px;
}

.cart {
  width: 350px;
  background: #fff;
  height: 90vh;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 9700;
  box-shadow: -4px 4px 20px rgba(0, 0, 0, 0.1);
  /* padding: 0 20px; */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cart .top {
  width: calc(100% - 40px);
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding: 15px 0;
  height: auto;
}

.cart .top .close {
  font-size: 20px;
  /* height: 26px;
  width: 26px; */
  border-radius: 50%;
  border: 1px solid #292d32;
  display: grid;
  place-items: center;
  cursor: pointer;
  padding: 0px 7px;
  padding-bottom: 3px;
  padding-left: 7.5px;
  font-family: verdana;
}

.cart .top .close:hover {
  background: #000;
  color: #fff;
}

.cart .product {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding: 0 20px;
  padding-bottom: 20px;
}

.cart .product img {
  width: 100%;
  height: auto;
}

.cart .product .price {
  /* padding-left: 10px; */
  display: flex;
  flex-direction: column;
}

.cart .product .price span {
  font-size: 16px;
}

.cart .product .price span:first-child {
  margin-bottom: 10px;
}

.cart .product .quantity {
  display: flex;
  padding: 5px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  height: 30px;
  font-size: 16px;
  color: #000;
}

.cart .product .quantity div {
  margin: 0 3px;
  width: 20px;
  display: grid;
  place-items: center;
  cursor: pointer;
}

.cart .product .quantity div:first-child {
  margin-left: 0;
}

.cart .product .quantity .desc {
  display: grid;
  place-items: center;
}

.cart .product .quantity .desc span {
  width: 10px;
  height: 1.5px;
  display: flex;
  background: #000;
  margin: 0;
}

.card .product .desc {
  margin-left: 0;
}

.cart .subtotal {
  width: calc(100% - 40px);
  margin-bottom: 10px;
}

.cart .subtotal section {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.cart .subtotal section span {
  font-family: "Noto Serif";
  font-weight: bold;
  letter-spacing: 1px;
  font-size: 16px;
}

.cart .subtotal section p {
  font-size: 14px;
  letter-spacing: 0.5px;
}

.cart button {
  width: calc(100% - 40px);
  margin: 10px 0;
  padding: 10px 0;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

.cart .checkout {
  background: #9c4727;
  color: #fccb85;
}

.cart .checkout:hover {
  background: #b15b3b;
}

.cart .view-cart {
  border: 2px solid #9c4727;
  color: #9c4727;
  background: #fff;
}

.cart .view-cart:hover {
  background: #9c4727;
  color: #fff;
}

.collabs {
  flex-wrap: wrap;
}

.email-field {
  margin-left: 5px !important;
}

@media (max-width: 1205px) {
  .collabs .images section img {
    width: 50%;
  }

  .youtube-reviews .videos {
    /* flex-direction: column; */
  }

  .youtube-reviews .videos section:first-child {
    margin-right: 0;
  }

  .youtube-reviews .videos section:nth-child(2) iframe {
    width: 900px;
    max-width: 100%;
    /* margin: 10px 0; */
  }
}

@media (max-width: 1165px) {
  .getToKnow {
    flex-direction: column;
  }

  .getToKnow section:first-child {
    margin-bottom: 50px;
  }

  .getToKnow section:nth-child(2) {
    margin-left: 0;
    padding: 0 10px;
  }

  .what-people main {
    flex-direction: column;
  }

  .what-people main .first {
    border-right: none;
    border-bottom: 2px solid rgba(252, 203, 133, 0.2);
    margin-bottom: 20px;
    padding-bottom: 20px;
  }

  .what-people main section:nth-child(2) {
    height: 450px;
  }

  .what-people h2 {
    padding: 0 10px;
  }
}

@media (max-width: 1150px) {
  .sedut {
    flex-direction: column;
  }

  .sedut .rs {
    margin-top: 30px;
  }

  .sedut .ls {
    padding: 0 10px;
  }

  .sedut .ls .images img {
    margin: 10px 30px 10px 0px;
  }

  /* .sedut .ls .images img:first-child {
    margin-left: 0;
  } */
}

@media (max-width: 1030px) {
  .main-footer {
    /* justify-content: center;
    align-items: center; */
    padding: 50px 20px;
    flex-wrap: wrap;
  }

  .main-footer section {
    margin: 30px 0;
    width: 50%;
  }
}

@media (max-width: 860px) {
  .digital-ads .second-section {
    flex-direction: column;
  }

  .digital-ads .first-section {
    margin-bottom: 0;
  }

  .digital-ads .first-section iframe {
    width: 100%;
    margin-bottom: 0;
  }

  .digital-ads .second-section iframe {
    width: 100%;
    margin: 10px 0 !important;
  }

  .youtube-reviews .videos {
    flex-direction: column;
  }

  .frame-section:nth-child(1) {
    width: 90%;
  }

  .youtube-reviews .videos .frame-section:nth-child(2) {
    flex-direction: row;
    width: 90%;
    height: 23vh;
  }
}

@media (max-width: 750px) {
  .collabs .images {
    flex-direction: column;
    padding: 0 10px;
  }

  .frame-div {
    height: 25vh;
  }

  /* .collabs .images section:first-child {
    margin-bottom: 20px;
  } */
}

@media (max-width: 700px) {
  .nav-bar {
    display: none;
  }

  .main-header-section .line {
    display: none;
  }

  .main-header-section .header-section .top {
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  }

  .mobile-nav {
    display: flex;
  }
}

@media (max-width: 670px) {
  .main-footer {
    /* justify-content: center;
    align-items: center; */
    flex-wrap: wrap;
    padding: 50px 20px;
  }

  .main-footer section {
    margin: 30px 0;
    width: 100%;
    height: auto;
  }
}

@media (max-width: 595px) {
  .getToKnow section:first-child:before {
    width: 90%;
    left: 1%;
  }
  .youtube-reviews .videos .frame-section:nth-child(1) {
    height: 50vh;
  }
  .youtube-reviews .videos .frame-section:nth-child(2) {
    flex-direction: column;
    height: unset;
  }

  .frame-div {
    height: 49vh;
  }

  .youtube-reviews .videos .frame-section:nth-child(2) iframe {
    height: unset !important;
  }
}

@media (max-width: 445px) {
  .ingredients .items .item {
    margin: 10px 0;
    width: calc(100% - 40px);
  }

  .ingredients .items .item img {
    width: 35%;
  }
}

.bannner-img {
  max-width: 100%;
  height: auto;
}

/* .images {
  display: flex;
  
  width: 100%;
  margin: 0 auto;
} */

.insta-img-div {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.insta-sub-div {
  max-width: 25%;
}

.insta-sub-div2 {
  max-width: 45%;
}

.insta-img {
  max-width: 100%;
  height: auto;
}

.yt-img {
  max-width: 100%;
  height: auto;
}

@media (max-width: 700px) {
  .insta-img-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }

  .insta-sub-div {
    max-width: 75%;
  }

  .insta-sub-div2 {
    max-width: 90%;
  }
}

/*-------usp------*/
.usp-sec {
  margin: 2rem auto;
  padding-top: 3rem;
  padding-bottom: 3rem;
  background-color: #9c4727;
}

.usp-text {
  font-size: 1.6rem;
  font-family: Noto Serif;
  font-weight: 600;
  line-height: 54px;
  letter-spacing: 0em;
  text-align: center;
  color: #fccb85;
}

.usp-icon-main-div {
  display: flex;
  justify-content: center;
  padding: 2rem 0em;
}

.usp-icon-sub-div {
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex-basis: 100%;
  text-align: center;

  align-items: center;
}

.usp-icon-sub-div > img {
  width: 20%;
}

.usp-big-text {
  color: #fccb85;
  font-size: 1.15rem;
}

.usp-small-text {
  color: #fccb85;
  font-size: 0.9rem;
  width: 70%;
  line-height: 150%;
}

@media screen and (max-width: 320px) {
  .youtube-reviews .videos .frame-section:nth-child(1) {
    height: 25vh;
  }

  .frame-div {
    height: 25vh;
  }
}

@media (max-width: 700px) {
  .usp-icon-sub-div > img {
    width: 38%;
  }

  .usp-big-text {
    color: #fccb85;
    font-size: 1rem;
  }

  .usp-small-text {
    color: #fccb85;
    font-size: 0.7rem;
    width: 70%;
    line-height: 150%;
  }
}

/* ---- sed -----*/
.sed-sec {
  margin: 4rem auto;
}

.sed-main-div {
  display: flex;

  align-items: center;
  gap: 1rem;
}

.sed-sub-div {
  flex-basis: 100%;
  max-width: 50%;
  margin-left: 5rem;
}

.sed-sub-div2 {
  flex-basis: 100%;
  max-width: 40%;
  margin-right: 5rem;
  justify-self: center;
}

.video1 {
  max-width: 100%;
  height: auto;
}

.sed-big-text {
  font-size: 1.75rem;
  font-family: "Noto Serif";
  font-style: normal;
  font-weight: 600;
  line-height: 170%;
  margin-bottom: 1rem;
}

.sed-small-text {
  font-size: 1rem;

  font-family: "Humanst521 BT";
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}
.enquiryPagination > .pagination li.active a {
  background-color: #b15b3b;
}

.newsletterPagination > .pagination li.active a {
  background-color: #b15b3b;
}
.feedbackPagination > .pagination li.active a {
  background-color: #b15b3b;
}

@media (max-width: 700px) {
  .sed-main-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }

  .sed-sub-div {
    flex-basis: 100%;
    max-width: 80%;
    margin: 2rem auto;
  }

  .sed-sub-div2 {
    flex-basis: 100%;
    max-width: 80%;
    margin: auto;
    justify-self: center;
  }

  .video1 {
    max-width: 100%;
    height: auto;
  }
}

/*----ingredeants  ----------*/
.ing-sec {
  margin: 4rem auto;
  background-color: #ffeed9;
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.ing-title {
  text-align: center;
}

.ing-big-text {
  font-family: "Noto Serif";
  font-style: normal;
  font-weight: 600;
  font-size: 1.75rem;
  line-height: 150%;
  color: #382924;
}

.ing-small-text {
  font-family: "Humanst521 BT";
  width: 90%;
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 150%;
  color: #382924;
  margin: 1rem auto 2rem;
}

.ing-icons-div {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  text-align: center;

  gap: 1rem;
}

.ing-icons-div > div {
  background-color: #fde4c3;
  max-width: 15%;
  height: 15rem;
}

.ing-icons-div > div > img {
  max-width: 100%;
  height: auto;
}

.ing-div-title {
  font-family: "Humanst521 BT";
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 150%;
  color: #9c4727;
  margin: 0.5rem auto;
}

.ing-hid-text {
  display: none;
}

.ing-icons-div > div:hover img {
  display: none;
}

.ing-icons-div > div:hover hr {
  display: none;
}

.ing-icons-div > div:hover .ing-div-title {
  display: none;
}

.ing-icons-div > div:hover .ing-hid-text {
  display: block;
  text-align: center;
  font-size: 1.1rem;
  font-family: "Humanst521 BT";
  font-weight: 400;
  padding-top: 8vh;
  padding-bottom: 8vh;
}

.ing-icons-div > div > hr {
  border: 1px solid #edc288;
}

@media (min-width: 1350px) and (max-width: 1500px) {
  .ing-icons-div {
    display: flex;
    flex-wrap: wrap;

    align-items: center;
    text-align: center;
    gap: 1rem;
  }

  .ing-icons-div > div {
    background-color: #fde4c3;
    max-width: 18%;
    height: 20rem;
  }
}

@media (max-width: 868px) {
  .ing-icons-div {
    display: flex;
    flex-wrap: wrap;

    align-items: center;
    text-align: center;
    gap: 1rem;
  }

  .ing-icons-div > div {
    background-color: #fde4c3;
    max-width: 22%;
  }
}

@media (max-width: 428px) {
  .ing-big-text {
    font-family: "Noto Serif";
    font-style: normal;
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 150%;
    color: #382924;
  }

  .ing-small-text {
    font-family: "Humanst521 BT";
    font-style: normal;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 150%;
    color: #382924;
    margin: 1rem auto 2rem;
  }

  .ing-icons-div {
    display: flex;
    flex-wrap: wrap;

    text-align: center;
    gap: 1rem;
  }

  .ing-icons-div > div {
    background-color: #fde4c3;
    max-width: 30%;
    height: 10rem;
  }

  .ing-icons-div > div > img {
    max-width: 100%;
    height: auto;
  }

  .ing-div-title {
    font-family: "Humanst521 BT";
    font-style: normal;
    font-weight: 400;
    font-size: 0.8rem;
    line-height: 150%;
    color: #9c4727;
    margin: 0.5rem auto;
  }

  .ing-icons-div > div:hover .ing-hid-text {
    display: block;
    text-align: center;
    font-size: 0.85rem;
    font-family: "Humanst521 BT";
    font-weight: 400;
    padding-top: 3vh;
    padding-bottom: 3vh;
  }
}

/* -------digital ads------ */
/* .digital-ads-item:nth-child(1){
  width: 100%;
} */

/*------Testimonial--------*/
.test-sec {
  margin: 4rem auto;
  text-align: center;
  background-color: #fffaf3;
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.test-title {
  font-family: "Noto Serif";
  font-style: normal;
  font-weight: 600;
  font-size: 1.75rem;
  line-height: 150%;
  color: #382924;
}

.prev-btn {
  float: left;
  margin-left: 3rem;
  display: flex;
  align-items: center;
  gap: 10px;
}

.prev-btn > p,
.next-btn > p {
  font-family: "Noto Serif";
  font-style: normal;
  font-weight: 700;
  font-size: 1rem;
  line-height: 150%;

  color: #382924;
}

.next-btn {
  float: right;
  margin-right: 3rem;
  display: flex;
  align-items: center;
  gap: 10px;
}

.carousel__list8 {
  display: flex;
  list-style: none;
  padding: 0;
  padding: 1rem 0 0;
  overflow: hidden;
  position: relative;
  width: 90vw;
  margin: 0 auto;
  max-width: 60rem;
}

.carousel__item8 {
  flex: 0 0 auto;
  max-width: 45%;
  padding: 1rem;
  margin-right: 10%;
  flex-basis: 100%;
}

.slide-card-num {
  font-family: "Noto Serif";
  font-style: normal;
  position: absolute;
  margin-left: 9.5%;
  margin-top: -2%;
  font-weight: 700;
  font-size: 1.8rem;
  line-height: 170%;
  width: 10%;
  text-align: left;
  color: rgba(0, 0, 0, 0.1);
}

.slide-card-text1 {
  position: relative;
  font-family: "Noto Serif";
  font-style: normal;
  font-weight: 700;
  font-size: 1.7rem;
  line-height: 150%;
  color: #382924;
  margin-bottom: 1.75rem;
  z-index: 10;
}

.slide-card-text2 {
  position: relative;
  font-family: "Noto Serif";
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 150%;
  color: #382924;
  z-index: 10;
}

.slide-card-img {
  color: #f5e8df;
  width: 4%;
  position: absolute;
  z-index: 0;
  filter: grayscale(100%);
  opacity: 0.2;
}

.user-popup {
  position: absolute;
  top: calc(100% + 10px);
  right: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 12px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  padding: 10px;
  z-index: 10;
}

.user-popup ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.user-popup li {
  margin: 16px 11px;
}

.user-popup a {
  text-decoration: none;
  font-size: 17px;
  margin: 0 15px;
  color: #9c4727;
  position: relative;
}

.user-popup a:after {
  content: "";
  border-bottom: 3px solid #9c4727;
  width: 0;
  /* Start with width 0 */
  transition: width 300ms linear;
  /* Only transition the width */
  position: absolute;
  bottom: -3px;
  /* Position the border underneath the text */
  left: 0;
  /* Start the border from the left */
}

.user-popup a:hover:after {
  width: 100%;
  /* Grow the width to 100% on hover */
}

@media screen and (max-width: 1024px) {
  .digital-ads-item:nth-child(1) {
    height: 70vh;
  }

  .videos{
    flex-direction: column;
  }
  .youtube-reviews .videos .frame-section:first-child{
    height: 37vh;
    width: 100%;
  }

  .youtube-reviews .videos .frame-section:nth-child(2){
    flex-direction: row;
    width: 100%;
  }

  .frame-div{
    height: 12vh;
  }

  .digital-ads-item:nth-child(1) {
    height: 29vh;
}
}

@media screen and (max-width:900px){
  .digital-ads-item:nth-child(1){
    height: 26vh;
  }

  .youtube-reviews .videos .frame-section:first-child {
    height: 30vh;
}

.youtube-reviews .videos .frame-section:nth-child(2){
  height: unset;
}
}

@media (max-width: 980px) and (min-width: 780px) {
  .slide-card-text1 {
    font-family: "Noto Serif";
    font-style: normal;
    font-weight: 700;

    color: #382924;
    margin-bottom: 1rem;
  }

  .slide-card-num {
    font-family: "Noto Serif";
    font-style: normal;
    position: absolute;
    margin-left: 7.5%;
    margin-top: -1.5%;
    font-weight: 700;
    font-size: 1.7rem;
    line-height: 170%;
    color: rgba(0, 0, 0, 0.1);
  }
}

@media (max-width: 768px) {
  .slide-card-num {
    font-family: "Noto Serif";
    font-style: normal;
    position: absolute;
    margin-left: 4%;
    margin-top: -2.2%;
    font-weight: 700;
    font-size: 1.8rem;
    line-height: 170%;
    width: 10%;
    text-align: left;
    color: rgba(0, 0, 0, 0.1);
  }

  .digital-ads-item:nth-child(1) {
    height: 55vh;
  }
  .digital-ads-item:nth-child(2),
  .digital-ads-item:nth-child(3) {
    height: 27vh;
  }

  .digital-ads-video:nth-child(1) {
    height: 100%;
  }
  .youtube-reviews .videos .frame-section:nth-child(2) iframe {
    height: unset !important;
}

.youtube-reviews .videos .frame-section:first-child {
  height: 71vh;
}


.frame-div {
    height: 22vh;
}
}
@media (max-width: 700px) {
  .carousel__list8 {
    display: flex;
    list-style: none;
    padding: 0;
    padding: 1rem 0 0;
    overflow: hidden;
    position: relative;
    width: 80vw;
    margin: 0 auto;
    max-width: 50rem;
  }

  .carousel__item8 {
    flex: 0 0 auto;
    width: 100%;
    padding: 0;
  }

  .slide-card-text1 {
    font-family: "Noto Serif";
    font-style: normal;
    font-weight: 700;

    font-size: 1.22rem;
    line-height: 150%;
    color: #382924;
    margin-bottom: 1rem;
  }

  .slide-card-num {
    font-family: "Noto Serif";
    font-style: normal;
    position: absolute;
    margin-left: 2rem;
    margin-top: -2%;
    font-weight: 700;
    font-size: 1.2rem;
    color: rgba(0, 0, 0, 0.1);
  }

  .user-popup {
    top: 55px !important;
    right: 15px !important;
  }

  .digital-ads-item:nth-child(1) {
    height: 50vh;
  }
  .digital-ads-item:nth-child(2),
  .digital-ads-item:nth-child(3) {
    height: 25vh;
  }
}

@media (max-width: 600px) {
  .slide-card-text1 {
    font-family: "Noto Serif";
    font-style: normal;
    font-weight: 700;

    font-size: 1.25rem;
    line-height: 150%;
    color: #382924;
    margin-bottom: 1rem;
  }

  .slide-card-num {
    font-family: "Noto Serif";
    font-style: normal;
    position: absolute;
    margin-left: 2%;
    margin-top: -3.1%;
    font-weight: 700;
    font-size: 1.1rem;
    line-height: 170%;
    color: rgba(0, 0, 0, 0.1);
  }

  .digital-ads-item:nth-child(1) {
    height: 43.5vh;
  }
  .digital-ads-item:nth-child(2),
  .digital-ads-item:nth-child(3) {
    height: 21vh;
  }
}


@media screen and (max-width: 540px){
  .digital-ads-item:nth-child(1),  .frame-div{
    height: 29vh;
  }

  .digital-ads-item:nth-child(2),.digital-ads-item:nth-child(3){
    height: 29vh;
    width: 70%;
  }

  .youtube-reviews .videos .frame-section:nth-child(1){
    height: 29vh;
    width: 90%;
  }
}

@media (max-width: 500px) {
  .test-title {
    font-family: "Noto Serif";
    font-style: normal;
    font-weight: 600;
    font-size: 1.2rem;
    line-height: 150%;
    color: #382924;
    margin: 0 0 1rem 0;
  }

  .carousel__list8 {
    display: flex;
    list-style: none;
    padding: auto;
    overflow: hidden;
    position: relative;
    margin: auto;
    width: 95%;
  }

  .carousel__item8 {
    flex: 0 0 100%;
    width: 100vw;
  }
  .slide-card-text1 {
    font-family: "Noto Serif";
    font-style: normal;
    font-weight: 700;

    font-size: 1.25rem;
    line-height: 150%;
    color: #382924;
    margin-bottom: 1rem;
  }

  .slide-card-text2 {
    font-family: "Noto Serif";
    font-style: normal;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 150%;

    color: #382924;
  }

  .slide-card-num {
    font-family: "Noto Serif";
    font-style: normal;
    position: absolute;
    margin-left: 3.6%;
    margin-top: -3%;
    font-weight: 700;
    font-size: 1.2rem;
    line-height: 170%;
    width: 15%;
    text-align: left;
    color: rgba(0, 0, 0, 0.1);
  }

  .digital-ads-item:nth-child(1),
  .digital-ads-item:nth-child(2),
  .digital-ads-item:nth-child(3) {
    height: 35vh;
    width: 80%;
  }
}
@media screen and (max-width: 430px){
  .digital-ads-item:nth-child(1),.digital-ads-item:nth-child(2), .digital-ads-item:nth-child(3), .frame-div{
    height: 20vh;
  }

  .youtube-reviews .videos .frame-section:nth-child(1){
    height: 20vh;
  }
}
@media (max-width: 425px) {
  .digital-ads-item:nth-child(1),
  .digital-ads-item:nth-child(2),
  .digital-ads-item:nth-child(3) {
    display: flex;
    height: 35vh;

  }

  .youtube-reviews .videos .frame-section:nth-child(2){
    flex-direction: column;
  }

  .youtube-reviews .videos .frame-section:nth-child(1),
  .frame-div {
    height: 35vh;
    width: 90%;
  }
}

@media (max-width: 420px) {
  .slide-card-text1 {
    font-family: "Noto Serif";
    font-style: normal;
    font-weight: 700;

    font-size: 1.2rem;
    line-height: 150%;
    color: #382924;
    margin-bottom: 1rem;
  }

  .slide-card-num {
    font-family: "Noto Serif";
    font-style: normal;
    position: absolute;
    margin-left: 2.75%;
    margin-top: -3.3%;
    font-weight: 700;
    font-size: 1.1rem;
    line-height: 170%;
    color: rgba(0, 0, 0, 0.1);
  }
}

@media screen and (max-width: 414px){
  .digital-ads-item:nth-child(1),.digital-ads-item:nth-child(2), .digital-ads-item:nth-child(3), .frame-div{
    height: 21vh;
  }

  .youtube-reviews .videos .frame-section:nth-child(1){
    height: 21vh;
  }
}
@media (max-width: 375px) {
  .digital-ads-item:nth-child(1),
  .digital-ads-item:nth-child(2),
  .digital-ads-item:nth-child(3) {
    height: 30vh;
  }

  .youtube-reviews .videos .frame-section:nth-child(1),
  .frame-div {
    height: 30vh;
  }
}
@media (max-width: 370px) {
  .slide-card-text1 {
    font-family: "Noto Serif";
    font-style: normal;
    font-weight: 700;

    font-size: 1rem;
    line-height: 150%;
    color: #382924;
    margin-bottom: 1rem;
  }

  .slide-card-num {
    font-family: "Noto Serif";
    font-style: normal;
    position: absolute;
    margin-left: 3%;
    margin-top: -3.3%;
    font-weight: 700;
    font-size: 1rem;
    line-height: 170%;
    color: rgba(0, 0, 0, 0.1);
  }
}
@media screen and (width: 360px){
  .digital-ads-item:nth-child(1),.digital-ads-item:nth-child(2), .digital-ads-item:nth-child(3), .frame-div{
    height: 20vh;
  }

  .youtube-reviews .videos .frame-section:nth-child(1){
    height: 20vh;
  }
}
@media (max-width: 320px) {
  .digital-ads-item:nth-child(1),
  .digital-ads-item:nth-child(2),
  .digital-ads-item:nth-child(3) {
    height: 25vh;
  }

  .youtube-reviews .videos .frame-section:nth-child(1),
  .frame-div {
    height: 25vh;
  }
}

/*--insta new----*/
.img__wrap {
  position: relative;
  height: 200px;
  width: 257px;
}

.img__description_layer {
  /* position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(36, 62, 206, 0.6);
  color: #fff;
  visibility: hidden;
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  
  transition: opacity .2s, visibility .2s; */

  position: absolute;
  display: flex;
  flex-direction: column;
  top: 0;
  left: 0;
  width: 80%;
  height: 100%;
  padding: 14px 2px;
  font-size: 0.9em;
  background: rgba(0, 0, 0, 0.65);
  color: #ffffff;
  opacity: 0;
  transition: opacity 0.2s;
}

.img__wrap:hover .img__description_layer {
  visibility: visible;
  opacity: 1;
}

.img__description {
  /* transition: .2s;
  transform: translateY(2em);
  white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis;  */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}

.img__wrap:hover .img__description {
  /* transform: translateY(-2em);
  white-space: wrap; 
  overflow: hidden;
  text-overflow: ellipsis;  */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}

/*----------------------------Products----------------------------------------*/
.product-sec {
  margin-top: 40px;
  height: 70vh;
  width: 100%;
  background-color: #000;
}

.carousel-container {
  margin-top: 5vh;
  margin-bottom: 10vh;
  width: 100%;
  overflow: hidden;
  position: relative;
}

.carousel {
  display: flex;
  transition: transform 0.3s ease-in-out;
  gap: 1rem;
}

.carousel-item {
  flex: 0 0 50%;
  /* Display only two items at a time */
  padding: 10px;
  text-align: center;
  background-color: #f6f6f6;
}

.carousel-item img {
  width: 100%;
  max-height: auto;
  object-fit: cover;
}

.carousel-item h3 {
  margin: 10px 0;
}

.buy-now {
  display: inline-block;
  padding: 10px 10px;
  background-color: #9c4727;
  text-decoration: none;
  transition: background-color 0.3s ease-in-out;
}
.carousel-div-img {
  width: 100%;
  height: 290px;
}

#pimage {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

#shop-search {
  color: #fff;
  background-color: #9c4727;
  text-align: center;
  letter-spacing: 0.5px;
  cursor: pointer;
  border: none;
  border-radius: 2px;
  display: inline-block;
  height: 36px;
  line-height: 36px;
  padding: 0 16px;
  text-transform: uppercase;
}

.shop-reco {
  margin-left: 5rem;
  display: flex;
  gap: 1rem;
}

.shop-img-reco {
  max-width: 70%;
}

.shop-p-reco {
  font-size: 1.5rem;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: normal;
  max-width: 80%;
}

.shop-last {
  max-width: 100%;
  height: auto;
  margin: 0 auto;
}

.shop-last-div {
  max-width: 88%;
  height: auto;
  margin: 0 auto;
}

.shop-reco-div1 {
  display: flex;
  margin-left: 5rem;
}

.shop-reco-link {
  text-decoration: none;
  color: black;
  display: flex;
}

.shop-reco-div {
  margin-right: 2rem;
  margin-top: 1rem;
}

.shop-reco-img {
  width: 15rem;
  height: 15rem;
}

.shop-reco-h3 {
  font-size: 1.75rem;
  margin-left: 5rem;
}



.cart-chemist-btn {
  background-color: #9c4727;
  color: #fccb85;
  padding: 5px 10px;
  border: none;
  cursor: pointer;
}

.cart-chemist-btn:hover {
  background: #9c4727;
  color: #fff;
}

.red-dot {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 50%;
  top: -37px;
  right: -21px;
  font-size: 14px; /* Adjust font size as needed */
  font-weight: 800;
  color: #ffffff;
  background-color: red;
}

@keyframes blink {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@media (max-width: 600px) {
  .shop-reco-div1 {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-left: 1.5rem;
  }

  .shop-reco-link {
    text-decoration: none;
    color: black;
    display: flex;
    flex-direction: column;
  }

  .shop-reco-div {
    margin-right: 0rem;
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
  }

  .shop-reco-img {
    width: 7rem;
    height: 7rem;
  }

  .shop-reco-h3 {
    font-size: 1rem;
    margin-left: 1rem;
  }

  .shop-p-reco {
    font-size: 1rem;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-break: normal;
    max-width: 88%;
  }
}



.delete-cart {
  margin-left: -7%;
  padding: 4px 5px;
  color: #9c4727;
  text-decoration: none;
  border: none;
  border-radius: 50%;
  transition: background-color 0.3s ease-in-out;
}

.whatsapp-icon {
  position: fixed;
  bottom: 3rem;
  right: 3rem;
  cursor: pointer;
  z-index: 999;
}

.auth-cover {
  z-index: 885;
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  top: 0;
  left: 0;
}

.auth {
  background: #fff;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  /* height: 400px; */
  z-index: 999;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 20px;
  max-width: calc(100% - 20px);
}

.modal {
  position: fixed;
  z-index: 4000;
  justify-content: center;
  background: #fff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  /* height: 400px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 20px;
  max-width: calc(100% - 20px);
}

.modal-content {
  background-color: #fff;
  border-radius: 4px;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  max-width: 100%;
  width: 100%;
}

.close {
  position: absolute;
  color: #9c4727;
  top: 10px;
  right: 10px;
  font-size: 34px;
  font-weight: bold;
  cursor: pointer;
}

/* admin styles */

.html {
  font-size: 16px;
  scroll-behavior: smooth;
}

/* body {
  background: #f3f3f3;
  height: 100vh;
  overflow-y: hidden;
}  */

strong {
  font-weight: bold;
}

input::placeholder {
  color: #333;
}

h2 {
  font-size: 25px;
  text-transform: capitalize;
  font-weight: bold;
  color: #4e4e4e;
}

.side-bar {
  width: 270px;
  min-height: 100vh;
  max-height: 100vh;
  background: #9c4727;
  padding: 10px 0;
  overflow-y: auto;
}
.side-bar::-webkit-scrollbar {
  display: none;
}

.side-bar .links {
  width: 100%;
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.side-bar button {
  color: #9c4727;
  background: #e6cbcb;;
  padding: 10px 0px;
}

.side-bar .links a {
  font-size: 18px;
  color: #f3f3f3;
  width: 100%;
  padding: 10px 20px;
}

.side-bar button:hover {
  color: #9c4727;
  background: #f3f3f3;
}

.side-bar .nav-link.active {
  color: #ffffff;
  background: #9c4727;
}

.page {
  display: flex;
  overflow-y: auto;
}

.page .container {
  overflow-y: auto !important;
  height: 100vh;
  width: 100%;
  padding: 0 20px;
  /* display: flex; */
}

.about-us .pick-feature-image {
  width: 200px;
}

.about-us .pick-feature-image img {
  width: 200px;
  cursor: pointer;
}

.about-us .feature-image-preview {
  width: 400px;
  max-width: 100%;
  margin-top: 20px;
}

.about-us .sec {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.save {
  width: 200px;
  margin: 20px 0;
}

.ck-content {
  height: 50vh;
  background: red;
  font-family: "Roboto", sans-serif !important;
}

.ck-content ul,
.ck-content ol {
  margin-left: 15px;
}

.ck-content ul li {
  list-style: circle !important;
}

.about-content {
  margin-bottom: 20px;
}

.admin-blog .container .posts {
  margin-top: 30px;
}

.admin-blog .container .post {
  width: calc(100% - 20px);
  background: #fff;
  padding: 15px;
  display: flex;
  cursor: pointer;
  margin: 20px 0;
  color: #000;
}

.admin-blog .container .post img {
  width: 100%;
  height: 100%;
  margin-right: 10px;
}

.admin-blog .container .post h3 {
  font-size: 20px;
}

.admin-blog .container .post p {
  font-size: 16px;
  margin-bottom: 10px;
}

.admin-blog .container .post span {
  margin-top: 15px;
  display: block;
  color: #9c4727;
  text-transform: capitalize;
  letter-spacing: 0.5px;
  font-size: 16px;
}

.add_blog {
  background-color: #9c4727;
  padding: 10px;
  border: none;
}

.add_blog a {
  color: white;
}

.add-blog-post .container main {
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-direction: column;
  margin-bottom: 30px;
}

.add-blog-post .container main section {
  display: flex;
  width: 100%;
}

.add-blog-post .container main .right-side {
  background: #fff;
  width: 300px;
  margin: 0;
  padding: 10px;
  margin-left: 20px;
}

.add-blog-post .container main .ck-content,
.add-blog-post .container main .ck-editor {
  width: calc(100vw - 625px);
}

.ck-content:focus {
  border: 1px solid #9c4727 !important;
  outline: none;
}

.add-blog-post .container main .ck-content {
  height: 75vh;
}

.add-blog-post .container main .right-side input,
.add-blog-post .container main .right-side textarea {
  margin: 0;
}

.add-blog-post .container main .right-side .sec {
  margin: 30px 0;
}

.right-side .add-blog {
  width: 100%;
}

.add-blog-post .container main .top,
.home .container .top {
  background: #9c4727;
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  display: flex;
}

.add-blog-post .container main .top .btns,
.home .container .top .btns {
  margin-left: auto;
}

.add-blog-post .container main .top .btns .btn,
.home .container .top .btns .btn {
  margin: 0 10px;
  background: rgb(252, 203, 133);
  color: #9c4727;
  font-weight: bold;
  text-transform: none;
}

/* .add-blog-post .container main .right-side .sec label {
  color: #333;
} */

.media-picker {
  width: 150px;
  /* height: 150px; */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 10px;
  flex-direction: column;
}

.media-picker button {
  margin: 5px 0;
  width: 100%;
}

.media-picker:hover {
  background: #f2f2f2;
}

.media-picker label img {
  width: 150px;
  /* opacity: .35; */
  cursor: pointer;
  height: 150px;
  border-radius: 10px;
}

.media-picker label img.opacity {
  opacity: 0.5;
}

.media-picker label video {
  width: 100%;
  height: 100%;
  display: block;
}

.media-picker label span {
  font-size: 14px;
  display: block;
  text-align: center;
  background: #666;
  color: #f3f3f3;
  padding: 2px 0;
}

.home .container {
  padding: 20px;
}

.home .sec {
  width: 100%;
  padding: 0 10px;
  margin-bottom: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding-bottom: 20px;
}

.home .sec h2 {
  font-size: 24px;
  /* border-bottom: 1px solid #000; */
  /* display: inline-block; */
}

.home .sec button {
  margin-bottom: 0;
}

.home .sec main {
  display: flex;
  flex-wrap: wrap;
}

.home .slider {
  height: auto;
}

.home .slider .add img {
  width: 100px;
}

.shop .slider .add img {
  width: 100px;
}

.home .slider .add {
  width: 100px;
  cursor: pointer;
}

.home .sec main .media-picker {
  margin: 10px;
}

.shop .sec main .media-picker {
  margin: 10px;
}

.shopslider {
  display: flex;
}

.home .section-a main {
  width: 100%;
  display: flex;
}

.home .section-a main section:first-child {
  margin-right: 20px;
}

.home .section-a main input {
  width: 700px;
}

.home .section-a main textarea {
  height: 200px !important;
}

.home .section-b main .card {
  padding: 20px 10px;
  margin: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
}

.home .section-b main .card input {
  width: 250px;
  margin-bottom: -10px;
}

.home .section-b main .card textarea {
  width: 250px;
  height: 100px;
}

.home .section-b main .card .media-picker {
  width: 200px;
  height: 200px;
}

.home .section-b main .card .media-picker img {
  width: 200px;
  height: 200px;
}

.home .section-e main {
  display: flex;
  flex-direction: column;
}

.home .section-e main h3 {
  font-size: 16px;
  font-weight: bold;
  color: #666;
  border-bottom: 2px solid rgba(0, 0, 0, 0.2);
  display: inline-block;
  padding-bottom: 5px;
}

.home .section-e main .videos {
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  flex-wrap: wrap;
}

.disFlex {
  display: flex;
  gap: 1rem;
}

.input-field-div {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  padding: 1%;
  border-radius: 10px;
  width: 49%;
}

.home .section-f main {
  display: flex;
}

.home .section-f .card {
  margin: 0 10px;
  display: flex;
  flex-direction: column;
  padding: 10px;
  align-items: center;
}

.home .section-f .card label {
  left: 0;
}

.home .section-h main {
  display: flex;
}

.home .section-i main {
  display: flex;
}

/* .home .section-h .add {
  cursor: pointer;
  width: 100%;
}
.home .section-i .add {
  cursor: pointer;
  width: 100%;
} */
.home .section-h .add img {
  width: 100px;
  cursor: pointer;
}

.home .section-i .add img {
  width: 100px;
  cursor: pointer;
}

.home .section-h main {
  display: flex;
  flex-wrap: wrap;
}

.home .section-i main {
  display: flex;
  flex-wrap: wrap;
}

.home .section-h main .card {
  margin: 10px;
  width: 250px;
  padding: 10px;
}

.home .section-h main .card textarea {
  height: 100px;
}

.home .section-d .media-picker {
  width: 200px !important;
}

.btn:hover {
  background: #7e3317 !important;
}

.err {
  color: red;
  font-size: 14px;
}

.blog .add-blog-post {
  color: #000;
  font-size: 20px;
  background: #fff;
  padding: 10px;
  text-transform: capitalize;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.2);
  margin-top: 20px;
  display: block;
  width: 170px;
  text-align: center;
}

.blog .add-blog-post:hover {
  background: #f2f2f2;
}

.login {
  background: #9c4727;
  height: 100vh;
  width: 100%;
}

.auth {
  background: #fff;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  /* height: 400px; */
  z-index: 999;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 20px;
  max-width: calc(100% - 20px);
}

.auth h2 {
  font-size: 24px;
  margin-bottom: 5px;
  letter-spacing: 1px;
}

.auth p {
  font-size: 16px;
  margin-bottom: 10px;
}

.auth input {
  font-size: 18px;
  color: #382924;
  width: 350px;
  margin: 10px 0;
  outline: none;
  /* border: 1px solid rgba(19, 14, 14, 0.3) !important; */
  max-width: 100%;
}

.auth input::placeholder {
  color: #382924;
  opacity: 1;
}

.auth button {
  background: #9c4727;
  width: 250px;
  padding: 10px 0;
  text-align: center;
  border: none;
  color: #fccb85;
  font-size: 16px;
  cursor: pointer;
  margin-top: 20px;
}

.auth button:hover {
  background: #b86649;
}

.create-user {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.create-user main {
  width: 500px;
  padding: 10px;
}

.create-user main label {
  left: 0 !important;
}

.create-user select {
  display: block !important;
}

.admin-users table {
  background: #fff;
}

.admin-users table tbody tr:nth-child(2n) {
  background: #f2f2f2;
}

.admin-users .add-user_ {
  font-size: 20px;
  margin-bottom: 20px;
  background: #c75e38;
  color: #fff;
  padding: 10px;
}

#shopslider {
  display: flex;
  align-content: flex-start;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.form input {
  width: 80%;
  padding: 0.5rem;
  margin-bottom: 1rem;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 1rem;
}

.form textarea {
  width: 80%;
  padding: 0.5rem;
  margin-bottom: 1rem;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 1rem;
  resize: none;
}

.form button {
  background-color: #007bff;
  color: #fff;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  border: none;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.form button:hover {
  background-color: #0056b3;
}

#saveshop {
  width: 200px;
  margin: 20px 9px;
}

.btn-link {
  margin: 1rem;
  background-color: #9c4727;
  color: white;
  width: 80%;
  cursor: pointer;
  height: 2rem;
}

.btn-link:hover {
  background-color: #9c4727;
}

/* ---- serach  ---- */
.search {
  width: 30rem;
  text-align: right;
  position: relative;
}

.search-box {
  border: 4px solid transparent;
  border-radius: 2px;
  font-size: 2rem;
  width: 100%;
  padding: 1rem;
  transition: width 0.3s;
}

.search-box:focus {
  width: 100%;
  outline: none;
}

.search-btn {
  height: 100%;
  width: 2rem;
  margin-top: -1rem;
  position: absolute;
  top: 50%;
  right: 0.5rem;
  opacity: 0.2;
  background-color: #9c4727;
  height: 2rem;
  border: 1px solid #7e3317;
  border-radius: 5%;
  background-repeat: no-repeat;
  background-size: 100%;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAQAAAAAYLlVAAACnElEQVR4AcXZsUsbYRjH8e+dh2s1SyAGJwMJuDj1BIcEhJQIOnTq5F+QOf0jIq79A7oFh7aYyVBEkaZDC3awECc1AUXRIqUQotfFocnjJe/dk+b9PKP65Md7z13ee3Uwk2SNHKmngs5TnbDLJQqjA+RYZ4OXuDzvkSYf+cAJ44fPAYFhHeAzVhlqBBGrRoax8KjSJYhRXap4KCVoECiqQQKFLC0CZbXIElOBOwJ9cUchzm2Y5QsveN4tdfY4o00HSDHHPKuUmOV5v/D5SSSJ0MXfIY+HBB55dkIvRIIIvJDR28dnFJ/9kHH0MFaVDehRxlSZnuxAFUMZunKQKBJFUQ4wXTIYqcmPZ5GoFmUEahjw5eJTJI6ivBD4jCS/csrEVZZfU4yQk5OPhrwjcoRygQ0GVdCQf73OUEfisaMkHk1HDJHkYeDX82jlBzo+kCSEyxruwDP/EK1DbsWnhHDFgNTpodWjLgY9NECKfnvoyS4p8wBngN5Z/ABtQK8dP0AH0OuYB5iMqfAAMque7HJtHmAOPdnlxjzAPHqyy5V5gFX0ZJfj8AAn9CvhoeVRol8zPMAuj/xrlhW0Vpg1D3ApflhGR3b4wTlDvI24i4u+w9y0uyVrM213U1qxuy2/Z8bui8m23VezgGW7L6cBLdIWXs9FBAsHFCLCJI9opFMKXEzkkEp/IbK0bEdI0LARQRzVWoigPKy+Z5tlWooIiuP6NhVmAEiPNwLkqHDEw5CGx2wyDQDRI8T7l80U19xwxTFNmpwzKM1nFsyeCw7jFymCAxYjrHDp8r9cUOCUYRZ4Bw6AxVV47QJYXIVXLliNsOSC1Qh/XLAa4ZuDmmIcH1l2AaytwhZfmaAkn/qOb7eYBofJekOJJX7znfccAvwFyB3OeNys7d4AAAAASUVORK5CYII=");
}

.search-btn:hover {
  outline: none;
  opacity: 0.4;
  cursor: pointer;
}

.search-btn:focus {
  outline: none;
  opacity: 0.6;
  background-color: transparent;
  height: 2rem;
}

ul.options {
  display: block;
  list-style: none;
  width: 100%;
  transition: width 0.3s;
  margin: auto;
  position: absolute;
  z-index: 100;
}

ul.options li {
  display: block;
  background: white;
  padding: 10px;
  width: 100%;
  border-radius: 2px;
}

ul.options li:hover {
  font-weight: bold;
  color: #000000;
  cursor: pointer;
  transition: 0.3s all;
}

ul.options li.option-active {
  background: whitesmoke;
  font-size: 1.5rem;
  color: #000000;
  background-color: white;
}

.no-options {
  color: black;
}

.autocompletez {
  display: flex;
  gap: 2rem;
}

.modal {
  position: fixed;
  top: 2rem;
  left: 0;
  width: 60%;
  height: 80vh;
  background-color: #fff;
  /* Semi-transparent background */
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  max-width: 100%;
  width: 100%;
}

.close {
  position: absolute;
  color: #9c4727;
  top: 10px;
  right: 1px;
  font-size: 34px;
  font-weight: bold;
  cursor: pointer;
}

.menu-toggle {
  color: white;
  padding-left: 10px;
  display: flex;
  gap: 2rem;
  font-size: 1.25rem;
}

.link-menu {
  color: white;
  padding: 7px 0 0 10px;
  font-size: 1.25rem;
  width: 100%;
}

.link-menu:hover {
  color: #9c4727;
  background: #f3f3f3;
}

.modal2 {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  /* Semi-transparent black color */
  backdrop-filter: blur(8px);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10000;
}

.modal-content2 {
  text-align: center;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  padding: 2rem;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black color */
  backdrop-filter: blur(8px); /* Apply blur effect */
  z-index: 999; /* Ensure the backdrop appears above other elements */
}

.contact-us .admin-con {
  width: 100%;
}

.headerfirstname {
  text-decoration: none;
  font-size: 17px;
  color: #9c4727;
  position: relative;
  cursor: pointer;
}

@media (max-width: 600px) {
  .carousel-item {
    flex: 0 0 97%;
    /* Display only two items at a time */
    padding: 10px;
    text-align: center;
    background-color: #f6f6f6;
  }
  #pimage {
    object-fit: fill;
  }

  .sedut .rs {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .sedut .rs video {
    max-width: 90%;
  }

  .getToKnow section img {
    width: 80%;
    height: 80%;
  }
}

/* Add these styles for card view  for my order*/
.my-order-card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.my-order-card {
  width: 100%;
  max-width: 300px;
  margin: 10px;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.my-order-card-header {
  text-align: center;
}

.my-order-card-details {
  margin-top: 15px;
}

.my-order-card-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  gap: 10px;
}

/* @media (max-width: 800px) {
  .carousel-item h3{
    font-size: medium;
  }
  
} */
