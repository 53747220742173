.my-account .cont {
    width: 100%;
    padding: 0 10px;
}

.my-account .cont h1 {
    text-align: center;
    margin: 0 auto;
    margin-top: 30px;
    color: rgba(0, 0, 0, 0.7);
    letter-spacing: 1px;
    margin-bottom: 50px;
    border-bottom: 2px solid rgba(0, 0, 0, 0.1);
    padding-bottom: 15px;
    font-size: 26px;
    width: 900px;
    max-width: 100%;
}

.my-account .cont .cont-order{
    display: flex;
    justify-content: center;
    align-items: center;
}
.my-account main {
    display: flex;
    justify-content: space-around;
}

.my-account main .rs {
    width: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: column;
}

.my-account main .rs h2 {
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
    color: rgba(0, 0, 0, 0.7);
    letter-spacing: 1px;
    font-size: 20px;
}

.my-account main .rs table {
    text-align: center;
    max-width: 100%;
    /* display: inline-block; */
    /* white-space: nowrap; */
    overflow-x: auto;
    border-spacing: 0px !important;
}



.my-account main .rs table tr th {
    border-right: 2px solid #FCCB85;
    background: #9C4727;
    color: #FCCB85;
    padding: 10px;
    letter-spacing: .5px;
    font-weight: normal;
}

.my-account main .rs table tr td {
    padding: 20px 10px;
    font-size: 16px;
    letter-spacing: .5px;
    color: #333;
}

.my-account main .rs table tr td div {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.my-account main .rs table tr td img {
    width: 35px;
    margin-right: 10px;
}

.my-account main .rs table tr td span {
    font-size: 16px;
    max-width: 70px;
}

.orderDeatils {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 12px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    padding: 10px;
    z-index: 10;
}

.orderDeatils span{
    font-size: 16px;
    /* white-space: nowrap; */
    font-family: "Noto Serif";
    font-weight: bold;
    letter-spacing: 0.5px;
    text-align: right;
}


.orderDeatils table {
    text-align: center;
    max-width: 100%;
    /* display: inline-block; */
    /* white-space: nowrap; */
    overflow-x: auto;
    border-spacing: 0px !important;
}

.orderDeatils .table-row {
    border: 2px solid rgba(0, 0, 0, 0.1) !important;
}

.orderDeatils table tr th {
    background: rgba(254, 234, 206, 0.5);
    /* color: #FCCB85; */
    padding: 10px;
    font-weight: normal;
    font-family: "Noto Serif";
    font-weight: bold;
    letter-spacing: 0.5px;
    text-align: center;
}

.orderDeatils table tr td {
    padding: 20px 25px;
    font-size: 16px;
    letter-spacing: .5px;
    font-size: 14px;
    /* white-space: nowrap; */
    font-family: "Noto Serif";
    font-weight: bold;
    letter-spacing: 0.5px;
    text-align: right;
}

.orderDeatils table tr td div {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.orderDeatils table tr td img {
    width: 35px;
    margin-right: 10px;
}

.orderDeatils table tr td span {
    font-size: 16px;
    max-width: 70px;
}
.orderDeatils .orderDetailsClose{
    position: absolute;
    top:2px;
    right: 10px;
    width: 20px;
    padding: 4px 5px;
    color: #9c4727;
    text-decoration: none;
    border: none;
    border-radius: 50%;
    transition: background-color 0.3s ease-in-out;
}

.additionalinfoname{
    position: fixed;
    display: flex;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 12px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    padding: 10px;
    z-index: 10;
}


@media (max-width: 950px) {
    .my-account main {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .my-account main .ls {
        margin-top: 50px;
    }

    .my-account .cont .cont-order{
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .my-account .cont h1{
        margin-bottom: 0px;
    }
}

@media (max-width: 450px) {

    .my-account main .rs table{
        display: inline-block;
        flex-wrap: nowrap;
    }
    .orderDeatils{
        width: 100%;
    }

}

