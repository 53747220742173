@font-face {
  font-family: Humanst521 BT;
  src: url(assets/fonts/Humanst521\ BT.ttf);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Humanst521 BT;
}